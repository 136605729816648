exports.data = [
  {
    value: "Advanced Innovation, Research and Discovery",
    label: " Advanced Innovation, Research and Discovery",
  },
  { value: "Humanitarian Activities", label: "Humanitarian Activities " },
  { value: "Health and Peace", label: "Health and Peace" },
  { value: "Health and Earth", label: "Health and Earth" },
  {
    value: "Traditional/Herbal Medicine - Ethnomedicine",
    label: "Traditional/Herbal Medicine - Ethnomedicine",
  },
  {
    value:
      "Education (Course content creator, Health Training/Trainer, Online Course)",
    label:
      "Education (Course content creator, Health Training/Trainer, Online Course)",
  },
  {
    value:
      "TeleMed(E-Health Program, Join our Medical & Health experts to attend to Patient Online as volunteers or Paid)",
    label:
      "TeleMed(E-Health Program, Jon our Medical & Health experts to attend to Patient Online as volunteers or Paid)",
  }
]
