import img1 from "../../assets/slide1.jpeg"
import img2 from "../../assets/slide2.jpeg"
import img3 from "../../assets/slide3.jpeg"
import img4 from "../../assets/slide4.jpeg"
import img5 from "../../assets/mq2.jpg"
import img6 from "../../assets/mq3.webp"

const sliderData = [
  {
    iframe: "https://www.youtube.com/embed/3GhbxwcuSpY",
    id: 1,
    image: img5,
  },
  {
    iframe: "https://www.youtube.com/embed/bmfZs42mW1o",
    id: 2,
    image: img6,
  },
  {
    image: img1,
    id: 3,
  },
  {
    image: img2,
    id: 4,
  },
  {
    image: img3,
    id: 5,
  },
  {
    image: img4,
    id: 6,
  },
];
export default sliderData