import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js"; 
import "bootstrap/dist/css/bootstrap.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store'
// import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <Provider store = { store }> 
  <React.StrictMode> 
    <App />
  </React.StrictMode>
  </Provider>, 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
