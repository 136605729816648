import dat1 from '../../assets/video_1.mp4'
import dat2 from '../../assets/video_2.mp4'
import dat3 from '../../assets/bg_service.jpg'
import dat4 from '../../assets/bg_aboutP.jpg'
// import dat3 from '../../assets/soul-promocard2.webp'
// import dat4 from '../../assets/trading-cards.png'

let data = [
    {
        video: dat1,
        picture: dat3,
        id:1
    },
    // {
    //     video: dat2,
    //     picture: dat4,
    //     id:2
    // },
]

export default data